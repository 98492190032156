import React from 'react';
import { Field, Form, Formik } from 'formik';
import { navigate } from 'gatsby';
import qs from 'qs';

import Layout from '../../../components/Layout/Layout';
import InputWrapper from '../../../components/UI/InputWrapper/InputWrapper';
import Label from '../../../components/UI/Label/Label';
import GuestWithAgeInput from '../../../components/GuestWithAgeInput';
import CustomSelect from '../../../components/UI/CustomSelect/CustomSelect';
import InputDropDowned from '../../../components/UI/InputDropDowned/InputDropDowned';
import CheckboxGroup from '../../../components/CheckboxGroup/CheckboxGroup';
import BudgetInput from '../../../components/BudgetInput';
import ExpandableBlock from '../../../components/ExpandableBlock/ExpandableBlock';
import itinerarySearchService from '../../../services/itinerarySearchService';
import StorageService from '../../../services/storage.service';
import FormikRadioGroup from '../../../components/RadioGroup/RadioGroup';
import InfoTooltip from '../../../components/InfoTooltip/InfoTooltip';
import { filterObjectByKeys } from '../../../helpers/helper-methods';
import { getSearchProfile } from '../../../constans/acitivity-rating-profile';
import { ItinerarySearchProfileKeys } from '../../../constans/activity-search';
import { CheckboxWithoutBinding } from '../../../components/UI/Checkbox/Checkbox';
import DateInput from '../../../views/search/FormElements/DateInput/DateInput';
import { MAX_ACTIVITY_GUESTS } from '../../../constans/validation';
import { DEFAULT_PROFILE_NAME } from '../../../constans/search';
import SelectDestination from '../../../components/SelectDestination';
import { ACTIVITY_CATEGORY_OPTIONS, AllOption } from '../../../constans/activity';
import { LOCALSTORAGE_KEYS } from '../../../constans/localstorage';
import {
  AccessibilityAndKidsOptions,
  ActivitiesOnFirstDayOptions,
  ActivitiesOnLastDayOptions,
  DailyLoadOfActivitiesOptions,
  FitnessRequirementsOptions,
  ItineraryPriceTypesOptions,
} from '../../../constans/itineraries-search';

import styles from './index.module.scss';

const ItinerariesSearch = () => {
  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const model = {};
    const {
      priceType,
      ageOfAdults,
      ageOfKids,
      categoriesFilters,
      checkin,
      checkout,
      // do not send this values
      adults,
      kids,
      locationName,
      currency,
      //
      ...restOfValues
    } = values;
    const userProfile = filterObjectByKeys(getSearchProfile(), ItinerarySearchProfileKeys);

    // String required by bakcned
    model.guestsAges = [...ageOfAdults, ...ageOfKids].map((v) => v.toString());
    model.startDate = checkin;
    model.endDate = checkout;
    model.budget = priceType;

    if (categoriesFilters.length) {
      model.categoriesFilters = categoriesFilters;
    }

    const { data: { uuid } } = await itinerarySearchService().getSearchUUID({
      ...restOfValues,
      ...model,
      currency,
      userProfile,
    });

    const redirectQuery = qs.stringify({
      uuid,
      locationName,
      currency,
      kids,
      adults,
    });

    setSubmitting(false);
    StorageService().set(LOCALSTORAGE_KEYS.ITINERARIES_SEARCH, values);
    navigate(`/itineraries?${redirectQuery}`);
  };

  const handleCheckAll = ({ setFieldValue, field }) => () => {
    setFieldValue(field, []);
  };

  const displaySelectedActivityTypes = (selectedOptions) => ACTIVITY_CATEGORY_OPTIONS
    .filter(({ value }) => selectedOptions.includes(value))
    .map(({ title }) => title);

  return (
    <div className="column-flexed-wrapper search-page">
      <Layout>
        <div className={`blue-grad-bg flex-grow-1 ${styles.bg}`}>
          <div className="container">
            <Formik
              enableReinitialize
              validateOnBlur={false}
              validateOnChange={false}
              initialValues={{
                adults: 1,
                kids: 0,
                ageOfAdults: [''],
                ageOfKids: [],
                activitiesOnFirstDay: ActivitiesOnFirstDayOptions[2].value,
                activitiesOnLastDay: ActivitiesOnFirstDayOptions[2].value,
                categoriesFilters: [],
                dailyLoadOfActivities: DailyLoadOfActivitiesOptions[1].value,
                accessibilityScore: AccessibilityAndKidsOptions[0].value,
                fitnessRequirementScore: FitnessRequirementsOptions[0].value,
                kidsAllowedScore: AccessibilityAndKidsOptions[0].value,
                currency: 'USD',
                priceType: 'ENTIRE_TRIPE',
                minPrice: 0,
                maxPrice: 100,
                checkin: '',
                checkout: '',
                ...StorageService().get(LOCALSTORAGE_KEYS.ITINERARIES_SEARCH),
              }}
              onSubmit={handleSubmit}
            >
              {({
                setFieldValue, errors, values, handleBlur, isSubmitting,
              }) => (
                <Form className={`${styles.form} col-xl-10 mx-auto`}>
                  <div className={`${styles.card} ${styles.mt60} container-bordered`}>
                    <div className="block-title">
                      Search parameters
                    </div>
                    <SelectDestination
                      isRegionRequired
                      setFieldValue={setFieldValue}
                      initialValue={{
                        name: values?.locationName,
                        city: { id: values?.cityId },
                        country: { id: values?.countryId },
                        region: { id: values?.regionId },
                        district: { id: values?.districtId },
                      }}
                    />
                    <div className="row">
                      <InputWrapper containerClasses="col-12 col-lg">
                        <Label>Adults</Label>
                        <GuestWithAgeInput
                          arrayName="ageOfAdults"
                          setFieldValue={setFieldValue}
                          name="adults"
                          placeholder="Add adults"
                          values={values}
                          errors={errors}
                          max={MAX_ACTIVITY_GUESTS}
                        />
                      </InputWrapper>
                      <InputWrapper containerClasses="col-12 col-lg">
                        <Label>Kids</Label>
                        <GuestWithAgeInput
                          arrayName="ageOfKids"
                          setFieldValue={setFieldValue}
                          name="kids"
                          placeholder="Add kids"
                          values={values}
                          errors={errors}
                        />
                      </InputWrapper>
                      <DateInput
                        label="Trip start and end dates"
                        errors={errors}
                        setFieldValue={setFieldValue}
                        values={values}
                        calendarFields={['checkin', 'checkout']}
                      >
                        <div className="body-16 text-danger">
                          Itineraries max number of days: 15 days for Regions, 5 for cities, 2
                          for districts
                        </div>
                      </DateInput>
                    </div>
                    <div className="row">
                      <InputWrapper containerClasses="col-12 col-lg">
                        <Label>Activities on first day</Label>
                        <CustomSelect
                          options={ActivitiesOnFirstDayOptions}
                          name="activitiesOnFirstDay"
                        />
                      </InputWrapper>
                      <InputWrapper containerClasses="col-12 col-lg">
                        <Label>Activities on last day</Label>
                        <CustomSelect
                          options={ActivitiesOnLastDayOptions}
                          name="activitiesOnLastDay"
                        />
                      </InputWrapper>
                      <InputWrapper containerClasses="col-12 col-lg">
                        <Label>Daily load of activities</Label>
                        <CustomSelect
                          options={DailyLoadOfActivitiesOptions}
                          name="dailyLoadOfActivities"
                        />
                      </InputWrapper>
                    </div>
                    <div className="row">
                      <InputWrapper containerClasses="col-12 col-lg">
                        <Label>Activity type</Label>
                        <InputDropDowned
                          id="categoriesFilters"
                          placeHolder="Select options"
                          dropdownClassName={styles.activityDropdown}
                          inputValues={displaySelectedActivityTypes(values.categoriesFilters).join(', ')}
                          displayedValueClassName={styles.inputDropdownedValue}
                        >
                          <CheckboxWithoutBinding
                            onChange={handleCheckAll({ setFieldValue, values, field: 'categoriesFilters' })}
                            checkboxClasses={`
                                ${styles.checkbox} ${styles.checkboxAll}
                                c-custom__check-bordered-primary
                                c-custom__custom-input
                                c-custom__check-sm
                                c-custom__check
                              `}
                            labelClasses={`${styles.checkboxText} ${styles.labelAll} flex flex-v-center`}
                            value={AllOption.value}
                            isChecked={!values?.categoriesFilters.length}
                          >
                            {AllOption.title}
                          </CheckboxWithoutBinding>
                          <CheckboxGroup
                            name="categoriesFilters"
                            valueField="value"
                            checkboxClassName={styles.checkbox}
                            wrapperClassName={styles.checkboxDropdowned}
                            titleClassName={`${styles.checkboxText} ${styles.label}`}
                            options={ACTIVITY_CATEGORY_OPTIONS}
                          />
                        </InputDropDowned>
                      </InputWrapper>
                      <BudgetInput
                        errors={errors}
                        values={values}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        radioClassName={styles.priceRadios}
                        label="Itinerary budget"
                        radioOptions={ItineraryPriceTypesOptions}
                      />
                      <InputWrapper containerClasses="col-12 col-lg">
                        <Label>Choose profile</Label>
                        <CustomSelect
                          options={[DEFAULT_PROFILE_NAME]}
                          name="profile"
                          disabled
                        />
                      </InputWrapper>
                    </div>
                    <ExpandableBlock
                      header={(
                        <div className={`${styles.expandableHeader} flex flex-v-center`}>
                          Filters
                        </div>
                      )}
                      containerStyles={`${styles.expandableHeaderWrapper} ${styles.notOverflowed}`}
                    >
                      <div className={styles.filtersWrapper}>
                        <div className={`${styles.filtersRow} flex flex-between flex-v-center`}>
                          <div className={styles.label}>
                            Accessibility:
                            <InfoTooltip tooltipTextClassName={styles.tooltipText}>
                              Refers to accessibility for visitors with reduced mobility
                            </InfoTooltip>
                          </div>
                          <Field
                            wrapperStyles={styles.radioFilters}
                            name="accessibilityScore"
                            options={AccessibilityAndKidsOptions}
                            component={FormikRadioGroup}
                          />
                        </div>
                      </div>
                      <div className={styles.filtersWrapper}>
                        <div className={`${styles.filtersRow} flex flex-between flex-v-center`}>
                          <div className={styles.label}>Fitness requirement:</div>
                          <Field
                            wrapperStyles={styles.radioFilters}
                            name="fitnessRequirementScore"
                            options={FitnessRequirementsOptions}
                            component={FormikRadioGroup}
                          />
                        </div>
                      </div>
                      <div className={styles.filtersWrapper}>
                        <div className={`${styles.filtersRow} flex flex-between flex-v-center`}>
                          <div className={styles.label}>Kids friendly:</div>
                          <Field
                            wrapperStyles={styles.radioFilters}
                            name="kidsAllowedScore"
                            options={AccessibilityAndKidsOptions}
                            component={FormikRadioGroup}
                          />
                        </div>
                      </div>
                    </ExpandableBlock>
                    <div className="flex">
                      <button
                        type="submit"
                        className="btn btn-uppercased btn_common btn_mld t-700 ml-auto"
                        disabled={!values?.regionId || isSubmitting}
                      >
                        submit
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default ItinerariesSearch;
